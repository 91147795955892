import React, { useState, useContext, useEffect } from 'react';
import Link from '../components/atoms/Link/Link';
import AuthContext from '../context/AuthProvider';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import Button from '../components/atoms/Button/Button';
import Dialog from '../components/atoms/Dialog/Dialog';
import { getStorage, relocate, validateEmail } from '../helpers/general';
import * as pcStyles from './login.module.css';
import * as mpsStyles from './login.mps.module.css';
import { navigate } from 'gatsby';
import { Center, Image, useToast } from '@chakra-ui/react';
import { storeStyle } from '../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const validForm = email => {
  if (!email) {
    return 'Email is required.';
  }
  if (!validateEmail(email)) {
    return 'Invalid email address.';
  }
};

const ForgotPasswordPage = () => {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const handleForgotPassword = auth && auth.forgotPassword;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const userEmail = getStorage('_customEmail');
  const [email, setEmail] = useState('');
  const [fields, setFields] = useState({});
  const [init, setInit] = useState({});

  const toast = useToast();
  const [msg, setMessage] = useState({
    error: '',
    success: '',
    dialogSuccess: '',
    dialogError: ''
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      relocate(`/account/`);
    }

    if (!init && userEmail) {
      setFields({ ...fields, ...{ email: userEmail } });
    }
    setInit(true);
  }, [isLoggedIn, userEmail, fields, init]);

  const clearDialog = () => {
    setDialogOpen(false);
    relocate(`/login/`);
  };

  const handleCancel = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    setDialogOpen(false);
  };

  const attemptForgotPassword = e => {
    e.preventDefault();
    const validationMsg = validForm(email);
    if (validationMsg) {
      setMessage({ error: validationMsg });
      return;
    }
    setLoading(true);
    handleForgotPassword(email)
      .then(result => {
        // This returns true regardless as we don't confidently get anything back from BC yet.
        if (result) {
          toast({
            description:
              'If your email address exists in the system, you will receive an email with instructions to reset your password.',
            status: 'error',
            position: 'top-right',
            colorScheme: 'primary'
          });
        } else {
          toast({
            title: 'An error occurred',
            description: 'Sorry, something went wrong. Try again later.',
            status: 'error',
            position: 'top-right',
            colorScheme: 'secondary'
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!isLoggedIn && userChecked) {
    return (
      <Layout className={styles.root}>
        <Seo title="Reset Password" />
        <Container className={'container'}>
          <div className={`grid grid-50 ${styles.loginContainer}`}>
            <div className={`${styles.loginForm} center`}>
              {/* <h6>Forgot Your Password?</h6> */}
              <h1 className="h6-login new-customer-text line-height-forgot-pass">
                Forgot Your Password?
              </h1>
              <p className={styles.forgotText}>
                If you've forgotten your password, enter your email address below and follow the
                reset instructions
              </p>
              <form noValidate onSubmit={e => attemptForgotPassword(e)}>
                <div className="inputs">
                  <div className={`formField ${styles.forgotPasswordInput}`}>
                    <label className={styles.formFieldLabel} htmlFor="email">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Email Address"
                      onChange={e => setEmail(e.target.value)}
                    />
                    {msg.error && <p>{msg.error}</p>}
                  </div>
                  <Button level="tertiary" type="buttonSubmit" loading={loading} disabled={loading}>
                    Send
                  </Button>
                </div>
              </form>
              <div className={styles.forgotPasswordLinkDiv}>
                <Link url="/login/">Back to Login</Link>
              </div>
            </div>
            <div className={styles.createAccount}>
              <h1 className="h6-login new-customer-text line-height-forgot-pass">New Customer?</h1>
              <p>Create an account with us and you'll be able to:</p>
              <ul className="bullets">
                <li>Check out quickly every time</li>
                <li>Track your orders</li>
                <li>Manage any subscriptions</li>
                <li>Receive weekly discounts and coupons</li>
              </ul>
              <Button onClick={() => navigate('/signup/')} level="tertiary" type="button">
                Create account
              </Button>
            </div>
          </div>

          <Center>
            <Image m={0} mt={4} src="/login.png" alt="login" />
          </Center>

          <Dialog
            open={dialogOpen}
            title="Reset request made"
            size="sm"
            hideBtnCancel
            disableBackdropClick
            onOk={() => clearDialog()}
            onClose={(event, reason) => handleCancel(event, reason)}
          >
            {msg.dialogSuccess && <p>{msg.dialogSuccess}</p>}
            {msg.dialogError && <p>{msg.dialogError}</p>}
          </Dialog>
        </Container>
      </Layout>
    );
  } else return null;
};

export default ForgotPasswordPage;
